import { createApp } from 'vue'
import App from './App.vue'
//import socketio from 'socket.io-client';
//import VueSocketIO from 'vue-socket.io';
//export const SocketInstance = socketio('http://localhost:3000');

import dayjs from 'dayjs'
let relTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relTime);

let app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')
//app.use(VueSocketIO, SocketInstance);
