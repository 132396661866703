<template>
  <div class="hello">
    <h1>#thisisdevote</h1>
    <small>{{ infomsg }}</small>
    <div :class="tweet.author.username == 'thisisdevote' ? 'self' : tweet.tweet_content.slice(0,2) == 'RT' ? 'rt' : ''" class="tweet" v-for="tweet in tweets" :key="tweet.tweet_id">
      <div class="tweetUserInfo">
        <img class="profimg" :src="tweet.author.image" alt="Profile Picture" width="32"><br>
        <b>{{ tweet.author.display_name }} <small>(@{{ tweet.author.username }})</small></b><br>
        <a :href="'https://twitter.com/'+tweet.author.username+'/status/'+tweet.tweet_id">View on Twitter</a>
      </div>
      <p v-html="tweet.tweet_content"></p>
      <div class="tweetMetrics">
        <small>{{ this.$dayjs().to(this.$dayjs(tweet.timestamp)) }}</small><br>
        <small>RT: {{ tweet.metrics.retweet_count }} - QT: {{ tweet.metrics.quote_count }} - Replies: {{ tweet.metrics.reply_count }} - Likes: {{ tweet.metrics.like_count }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HashtagStack',
  data() {
    return {
      tweets: [],
      infomsg: "",
    }
  },
  methods: {
    refresh() {
      this.infomsg = "Reloading..."
      fetch("https://comms-api.devote.radio/hashtag")
      .then(res => res.json())
      .then(res => {
        if (res._data.error) {
          this.infomsg = `Error retrieving data - (${res._data.msg.status} ${res._data.msg.statusText})`
      } else {
        this.tweets = res._data;
        if (this.tweets.length == 0) {
          this.infomsg = "No results";
        } else {
          this.infomsg = "";
        }
      }
    });
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style>
  .tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 10px;
    width: 20vw;
    transition-property: box-shadow;
    transition-duration: 0.2s;
  }

  .tweet.rt {
    background-color:#00ba7caa
  }

  .tweet.self {
    background-color: #fca96baa
  }

  .tweet:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  }

  .profimg {
    border-radius: 50%;
  }

  .tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
</style>
