<template>
  <div>
    <button @click="refreshAll()">Refresh All</button>
    <!--<p>Auto-refreshing in: {{ interval }} | Enable Auto Refresh -> <input type="checkbox" v-model="autoRefreshEnabled" value="true"></p>-->
    <div class="container">
      <Whatsapp ref="wa" class="item"/>
      <Sms ref="sms" class="item"/>
      <Hashtag ref="hash" class="item"/>
      <Mentions ref="tags" class="item"/>
    </div>
  </div>
  
</template>

<script>
import Whatsapp from './components/Whatsapp.vue'
import Sms from './components/Sms.vue'
import Hashtag from './components/Hashtag.vue'
import Mentions from './components/Mentions.vue'

export default {
  name: 'DRComms',
  components: {
    Hashtag,
    Mentions,
    Whatsapp,
    Sms
  },
  data() {
    return {
      interval: 15,
      autoRefreshEnabled: false
    }
  },
  methods: {
    doCountdown() {
      if (!this.autoRefreshEnabled) {
        return;
      }
      this.interval--;
      if (this.interval == -1) {
        this.refreshAll();
      }
    },
    refreshAll() {
      this.interval = 15
      this.$refs.tags.refresh();
      this.$refs.hash.refresh();
      this.$refs.sms.refresh();
      this.$refs.wa.refresh();
    }
  },
  mounted() {
    this.refreshAll();
    this.intID = setInterval(this.doCountdown,1000); //deepscan-disable-line
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container {
  display: flex;
  justify-content: space-around;
  width:100%;
}

.item {
  width: 25vw;
  height: 80vh;
  border: 1px dashed black;
  overflow: scroll;
}
</style>
