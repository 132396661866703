<template>
  <div class="hello">
    <h1>SMS</h1>
    <p>Text 07700 160 338. Texts cost one standard network rate message to a UK mobile. Please make sure you have the bill payer's permission before texting. Full terms and privacy notice at devote.radio</p>
    <small>{{ infomsg }}</small>
    <div class="msg" v-for="msg in msgs" :key="msg.msg_id">
      <img @click="requestDel(msg.msg_id)" src="https://img.icons8.com/material-outlined/512/trash.png" alt="Delete Icon" width="16" class="delimg">
      <div class="tweetUserInfo">
        <img class="profimg" src="https://img.icons8.com/material-outlined/512/sms.png" alt="SMS icon" width="32"><br>
        <b v-if="msg.name && msg.location">{{ msg.name }} from {{ msg.location }} <small>(@{{ msg.identifier }})</small></b>
        <b v-else>{{ msg.identifier }}</b><br>
      </div>
      <p v-html="msg.content"></p>
      <div class="tweetMetrics">
        <small>{{ this.$dayjs().to(this.$dayjs(msg.ts*1000)) }}</small><br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SMSStack',
  data() {
    return {
      msgs: [],
      infomsg: "",
    }
  },
  methods: {
    refresh() {
      this.infomsg = "Reloading..."
      fetch("https://comms-api.devote.radio/sms")
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          this.msgs = "Error retrieving data."
      } else {
        this.msgs = res;
        if (this.msgs.length == 0) {
          this.infomsg = "No results";
        } else {
          this.infomsg = "";
        }
      }
    });
    },
    requestDel(msgid) {
      fetch("https://comms-api.devote.radio/sms", {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: msgid
        })
      })
      .then(() => {
        this.refresh();
      })
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>

<style>
  .msg {
    display: inline-block;
    position: relative;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 0 16px 16px 16px;
    width: 20vw;
    transition-property: box-shadow;
    transition-duration: 0.2s;
  }

  .delimg {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer
  }

  .msg:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  }

  .msg p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
</style>
